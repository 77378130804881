import * as THREE from 'three';
import { SceneObject, SceneEvent, surface2space } from './three-utils';
import { GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import Field from './field';
import { Scene, Vector3 } from 'three';
import { totalmem } from 'os';
import { SceneManager } from '../MainScene';
import Cows from './cows';


class CowObstacle {
  root = new THREE.Object3D()
  index = 0
  obstacles: Obstacles
  age = 0
  isActive = false
  defaultHitRadius = 1.4
  hitRadius = this.defaultHitRadius
  isBoss = false

  variance = {
    run: Math.random(),
    direction: Math.random(),
  }

  position = new THREE.Vector3();

  constructor(obstacles: Obstacles) {
    this.obstacles = obstacles
    const xRange = this.obstacles.xRange;
    const zRange = this.obstacles.zRange;
    const x = xRange.min + (xRange.max - xRange.min) * Math.random();
    const z = zRange.min + (zRange.max - zRange.min) * Math.random();
    const v = new THREE.Vector3(x, 0, z);
    this.position.copy(v);
  }

  respawn() {
    this.isBoss = false

    if (this.obstacles.bossNumToSpawn > 0) {
      this.isBoss = true
      this.obstacles.bossNumToSpawn--;
    }

    const z = this.obstacles.zRange;
    this.position.setZ(z.max + (z.max - z.min) * Math.random());

    const x = this.obstacles.xRange;
    this.position.setX(x.min + (x.max - x.min) * Math.random());

    if (this.isBoss) {
      this.position.setZ(z.max + 10);
      this.position.setX(x.min + (x.max - x.min) * Math.random() * 0.5);
    }
  }

  update(delta: number) {
    if (!this.isActive) {
      this.root.position.copy(new THREE.Vector3(0, 0, this.obstacles.zRange.max));
      this.root.scale.setScalar(0);
      this.root.updateMatrix();
      return;
    } 
    let cowSpeed = 1
    let speed = 0;
    if (this.isBoss) {
      const bossScale = 4
      this.root.scale.setScalar(bossScale);
      this.hitRadius = this.defaultHitRadius * bossScale
      speed = Field.shared.speed + cowSpeed * bossScale;
    } else {
      this.root.scale.setScalar(1);
      this.hitRadius = this.defaultHitRadius
      speed = Field.shared.speed + cowSpeed;
    }

    this.age += delta

    this.position.add(new THREE.Vector3(0, 0, -speed * delta));

    if (this.position.z < this.obstacles.zRange.min) {
      this.respawn();
    }
    
    const {position, rotation} = surface2space(this.position);

    // micro motion
    const t = this.age;

    position.x += 3 * Math.sin(t * 2 + this.variance.direction * Math.PI * 2);

    rotation.x += 0.1 * Math.sin(t * 40 + this.variance.run * Math.PI * 2);
    rotation.y -= 0.5 * Math.cos(t * 2 + this.variance.direction * Math.PI * 2);
    rotation.y += Math.PI;

    this.root.position.copy(position);
    this.root.rotation.setFromVector3(rotation);
    this.root.updateMatrix();
  }
}


export default class Obstacles implements SceneObject {
  isActive: boolean = true;
  root = new THREE.Object3D
  mixer: THREE.AnimationMixer | null = null;
  obstacles: CowObstacle[] = []
  meshs: THREE.InstancedMesh[] = []
  bossNumToSpawn = 0
  bossInterval = 20

  age = 0
  
  cowNum = 300;
  static initialActiveCowNum = 20;
  activeCowNum = Obstacles.initialActiveCowNum;
  xRange = { min: -10, max: 10, }
  zRange = { min: -40, max: 80, }

  prevSpawnTime = 0
  spawnInterval = 1

  static shared: Obstacles = null!

  constructor() {
    Obstacles.shared = this;
    this.loadModel();

  }

  async loadModel() {
    const loader = new GLTFLoader();
    const model = await loader.loadAsync('models/cow.glb');
    
    this.makeInstances(model);

    const mixer = new THREE.AnimationMixer(model.scene);
    for (let animation of model.animations) {
      let action = mixer.clipAction(animation);
      action.play();
    }

    mixer.timeScale = 5.0;

    this.mixer = mixer;
  }
  

  makeInstances(model: GLTF) {

    for (let i = 0; i < this.cowNum; i++) {
      const tree = new CowObstacle(this);
      tree.index = i;
      this.obstacles.push(tree);
    }


    model.scene.traverse(object => {
      if (object instanceof THREE.Mesh) {
        const mesh = object;
        const instancedMesh = new THREE.InstancedMesh(
          mesh.geometry,
          mesh.material,
          this.obstacles.length,
          );

        instancedMesh.castShadow = true
        
        this.meshs.push(instancedMesh);
      }
    })

    for (let mesh of this.meshs) {
      this.root.add(mesh);
    }
  }

  update(delta: number) {
    if (SceneManager.shared.mode === 'intro') {
      return
    }

    this.age += delta;

    this.activeCowNum += delta;

    this.mixer?.update(delta);

    const validNum = this.obstacles.filter(e => e.isActive).length;
    if (validNum < this.activeCowNum) {
      const rest = this.obstacles.filter(e => !e.isActive); 
      if (rest.length > 0) {
        const obs = rest[0];
        obs.isActive = true;
        obs.position.z = this.zRange.max + (this.zRange.max - this.zRange.min) * Math.random();
      }
    }

    for (let tree of this.obstacles) {
      tree.update(delta);
    }

    for (let mesh of this.meshs) {
      for (let tree of this.obstacles) {
        mesh.setMatrixAt(tree.index, tree.root.matrix);
      }
      mesh.instanceMatrix.needsUpdate = true;
      mesh.matrixWorldNeedsUpdate = true;
    }
  }

  spawnBoss(num: number) {
    this.bossNumToSpawn += num;
  }
  
  on(event: SceneEvent): void {
    if (event.name == "restart") {
      for (let obs of this.obstacles) {
        obs.isActive = false;
      }
      this.activeCowNum = Obstacles.initialActiveCowNum;
    }

    if (event.name == "level-up") {
      this.spawnBoss(Math.floor((SceneManager.shared.level / 3) + 1))
    }
  }

  remove() {
    this.root.removeFromParent();
  }
}


