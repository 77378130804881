import * as THREE from 'three';
import Field from './field';

export type SceneEvent = {
  name: string,
  data?: any
}

export interface SceneObject {
  isActive: boolean
  root: THREE.Object3D
  update(delta: number):void
  remove():void
  on(event: SceneEvent): void
}

export class SceneObjectBase implements SceneObject {
  isActive: boolean = true
  root: THREE.Object3D = new THREE.Object3D()
  update(delta: number):void {

  }
  remove():void {
    this.isActive = false
    this.root.removeFromParent();
  }
  on(event: SceneEvent): void {

  }

}

export function surface2space(position: THREE.Vector3) {
  const radius = Field.shared.radius;
  const angle = position.clone().divideScalar(radius);
  angle.y = 0;
  const x = radius * Math.sin(angle.x);
  const z = radius * Math.sin(angle.z);
  const y = Math.sqrt(radius * radius - x * x - z * z) * (radius + position.y) / radius - radius;

  return {
    position: new THREE.Vector3(x, y, z),
    rotation: new THREE.Vector3(angle.z, 0, angle.x),
  };
}

