import * as THREE from 'three';
import { TextureLoader, Vector3 } from 'three';
import { SceneObject, SceneEvent, SceneObjectBase, surface2space } from './three-utils';
import Field from './field';
import { SceneManager } from '../MainScene';
import { threadId } from 'worker_threads';
import { GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import { timeStamp } from 'console';

export default class Fuji extends SceneObjectBase {
  constructor() {
    super()
    this.loadObject()
    this.root.scale.setScalar(100);
    this.root.position.set(80, -130, 400)
    this.root.rotation.setFromVector3(new Vector3(Math.PI / 180 * 10, 0, 0))
  }

  async loadObject() {
    const loader = new GLTFLoader()
    const gltf = await loader.loadAsync('models/fuji.glb')
    this.root.add(gltf.scene);
  }
}
