import * as THREE from 'three';
import * as utils from './utils';
import { SceneObject, SceneEvent } from './three-components/three-utils';
// import { Container } from "@material-ui/core";
import { useState, useEffect, useRef, useContext } from 'react';
import { GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Stats from 'three/examples/jsm/libs/stats.module';
import Field from './three-components/field';
import SceneObjects from './SceneObjects';
import { mainModule } from 'process';
import { threadId } from 'worker_threads';

export class SceneManager {
  container: HTMLDivElement
  mode: "game" | "intro" | "finished" = "intro"

  mouse = {x: 0, y: 0}
  cameraMouse = {x: 0, y: 0}

  scene = new THREE.Scene();
  camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 10000 );
  baseCameraPosition = new THREE.Vector3();
  introCameraPos = new THREE.Vector3(2, 2, 5);
  gameCameraPos = new THREE.Vector3(-2, 17, -20);
  targetCameraRotation = new THREE.Vector3(0, 0, 0);
  cameraRotation = new THREE.Vector3(0, 0, 0);

  renderer = new THREE.WebGLRenderer({alpha: true});
  controls = new OrbitControls(this.camera, this.renderer.domElement);
  stats: Stats = new (Stats as any);
  shouldShowStats = false
  clock = new THREE.Clock();
  age = 0
  score = 0
  level = 1
  prevLevel = this.level

  mixer: THREE.AnimationMixer | null = null;

  isActive = true;
  sceneObjects = new SceneObjects(this.scene)

  static shared: SceneManager = null!

  constructor(container: HTMLDivElement) {
    SceneManager.shared = this;
    this.container = container;
    this.initThree();

    this.update();
    // this.startGame();
    // this.finish();
  }

  deinit() {
    this.renderer.domElement.remove();
    this.isActive = false;
  }

  initThree() {
    const {
      scene, camera, 
      renderer,
    } = this;

    // camera.position.set(2, 2, 5);
    this.baseCameraPosition = this.mode == "intro" ? this.introCameraPos: this.gameCameraPos;
    this.updateCameraPos();

    scene.add( camera );

    const ambientLight = new THREE.AmbientLight(0xFFFFFF, 1.0);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xFFFFFF, 1.0);
    const side = 20
    directionalLight.shadow.camera.top = side
    directionalLight.shadow.camera.bottom = -side
    directionalLight.shadow.camera.left = -side
    directionalLight.shadow.camera.right = side
    directionalLight.castShadow = true;
    directionalLight.position.set(-1, 1, 1);
    directionalLight.position.multiplyScalar(10);

    scene.add(directionalLight);

    renderer.setClearColor("black", 1);
    const rect = this.container.getBoundingClientRect();
    renderer.setSize(rect.width, rect.height);
    if (window.innerWidth > 600) {
      renderer.setPixelRatio(1);
    } else {
      renderer.setPixelRatio(window.devicePixelRatio);
    }
    renderer.shadowMap.enabled = true;

    this.container.appendChild( renderer.domElement );
    if (this.shouldShowStats) {
      this.container.appendChild(this.stats.dom)
    }
  }

  update() {
    if (!this.isActive) {
      return;
    }

    requestAnimationFrame( this.update.bind(this) );

    this.stats.begin();

    const {
      renderer, scene, camera, clock
    } = this;

    let delta = clock.getDelta();

    // detect sleep
    if (delta > 1.0) {
      delta = 1.0;
    }

    this.age += delta;

    this.sceneObjects.update(delta)

    this.updateCameraPos();
    this.updateScore(delta);
    this.renderer.render(this.scene, this.camera);

    this.stats.end();
  }

  updateScore(delta: number) {
    if (this.mode != 'game') {
      return
    }

    this.score += Field.shared.speed * delta;    
    this.level = Math.floor(this.score / 200 + 1);

    if (this.level > this.prevLevel) {
      this.emit({name: "level-up", data: null})
    }

    this.prevLevel = this.level;
  }

  updateCameraPos() {
    const targetBaseCameraPos = this.mode == "intro" ?  this.introCameraPos: this.gameCameraPos;
    this.baseCameraPosition = this.baseCameraPosition.lerp(targetBaseCameraPos, 0.01);

    this.camera.position.copy(this.baseCameraPosition);
    this.camera.lookAt(new THREE.Vector3(0, 2, 0));

    this.targetCameraRotation.setX(this.cameraMouse.y * 0.2);
    this.targetCameraRotation.setY(this.cameraMouse.x * 0.2);
    this.cameraRotation.lerp(this.targetCameraRotation, 0.1);

    this.camera.rotateX(this.cameraRotation.x);
    this.camera.rotateY(this.cameraRotation.y);
  }

  onResize() {
    this.renderer.setSize( window.innerWidth, window.innerHeight );
  }

  emit(event: SceneEvent) {
    this.on(event)
    this.sceneObjects.on(event);
  }

  on(event: SceneEvent) {

  }

  startGame() {
    this.mode = "game";
    this.emit({name: "game-start"})
  }

  finish() {
    this.mode = 'finished';
  }

  restart() {
    this.age = 0
    this.score = 0;
    // this.level = 1;
    this.prevLevel = this.level;
    this.mode = "game";
    this.emit({name: 'restart', data: null});
  }

  skipIntro() {
    this.emit({name: 'skip-intro', data: null});
  }
}


const ResultView: React.FC<{score: number, level: number}> = ({score, level}) => {
  return <div
    style={{
      position: 'fixed',
      display: 'flex',
      flexDirection: "column",
      justifyContent: "center",
      color: 'white',
      fontSize: 32,
      width: '100%',
      height: '100%',
      textAlign: 'center',
      paddingTop: 32,
      fontWeight: 'bold',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      zIndex: 2,
      top: 0,
      left: 0,
    }} >

      <div>
        <div style={{fontSize: 24}} >
          はしりぬけた距離
        </div>

        <div style={{fontSize: 64, marginBottom: 12}}>
          {score} m
        </div>

        <div style={{fontSize: 24, marginBottom: 32}}>
          level: {level}
        </div>

        <div
          style={{
            width: '100%',
            paddingBottom: 60,
          } }
        >

          <div style={{
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            color: 'black',
            padding: '8px 16px',
            fontSize: 24,
            borderRadius: 16,
            width: 100,
            margin: '0 auto',          
            }} 
            onClick={(e) => {
              SceneManager.shared.restart();
            }}
            >
              リトライ
          </div>
        </div>
      </div>

  </div>
}


function SkipButton() {
  return <div style={{
    position: "fixed", 
    zIndex: 2,
    color: 'white', 
    fontSize: 24, 
    padding: 8,
    bottom: 8,
    right: 8,
    // marginRight: 8,
    // marginBottom: 8,
    }}
    onClick={() => SceneManager.shared.skipIntro()} >
    {"> skip"}
  </div>
}


export default function MainScene() {
  const containerRef = useRef<HTMLDivElement>(null!);
  const size = utils.useWindowSize();
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const [mode, setMode]= useState("intro");

  function init() {
    let main = new SceneManager(containerRef.current);

    let prevTouchPos = { x: 0, y: 0 };

    function update() {
      setMode(main.mode);
      setScore(Math.floor(main.score));
      setLevel(main.level);
    }

    function onResize() {
      main.onResize();
    }

    function onMouseMove(event: MouseEvent) {
      event.preventDefault();

      let dom = containerRef.current;
      let rect = dom.getBoundingClientRect();

      let aspect = window.innerWidth / window.innerHeight;

      let x = -(((event.pageX - rect.x) / rect.width) * 2 - 1);
      const y = -((event.pageY - (window.scrollY + rect.y)) / rect.height) * 2 + 1;

      x = utils.clamp(x * aspect * 2, -1, 1);

      main.mouse.x = x
      main.mouse.y = y

      main.cameraMouse.x = main.mouse.x / 2
      main.cameraMouse.y = main.mouse.y / 2
    }

    function onTouchStart(event: TouchEvent) {
      event.preventDefault();
      let pos = getPosFromTouch(event);
      prevTouchPos = pos;

      return false;
    }

    function getPosFromTouch(event: TouchEvent) {
      var x = 0, y = 0;
     
      if (event.touches && event.touches[0]) {
        x = event.touches[0].clientX;
        y = event.touches[0].clientY;
      } 

      let dom = containerRef.current;
      let rect = dom.getBoundingClientRect();

      x = (x - rect.x) / rect.width * 2 - 1
      y = (y - rect.y) / rect.height * 2 - 1
      y = -y

      return {x, y}
    }
    function onTouchMove(event: TouchEvent) {
      event.preventDefault();
      let pos = getPosFromTouch(event);

      let diff = {
        x: pos.x - prevTouchPos.x,
        y: pos.y - prevTouchPos.y,
      }

      main.mouse.x -= diff.x
      main.mouse.y -= diff.y

      if (main.mouse.y > 1) {
        main.mouse.y = 1;
      }

      if (main.mouse.y < -1) {
        main.mouse.y = -1;
      }

      if (main.mouse.x > 1) {
        main.mouse.x = 1;
      }

      if (main.mouse.x < -1) {
        main.mouse.x = -1;
      }

      main.cameraMouse.x = main.mouse.x / 2
      main.cameraMouse.y = main.mouse.y / 2

      prevTouchPos = pos;
      return false;
    }

    const timer = setInterval(update, 33);

    containerRef.current.addEventListener("resize", onResize, false);
    containerRef.current.addEventListener("mousemove", onMouseMove, false);
    containerRef.current.addEventListener("touchstart", onTouchStart, false);
    containerRef.current.addEventListener("touchmove", onTouchMove, false);
    function deinit() {
      containerRef.current.removeEventListener("resize", onResize);
      containerRef.current.removeEventListener("mousemove", onMouseMove);
      containerRef.current.removeEventListener("touchstart", onTouchStart);
      containerRef.current.removeEventListener("touchmove", onTouchMove);
      clearInterval(timer);
      main.deinit();
    }
    return deinit;
  }

  useEffect(init, [])

  const shouldShowResult = mode === "finished"
  const shouldShowSkip = mode === "intro"

  return (
    <div>
      <div
        style={{
          ...size,
          position: "relative",
          zIndex: 0,
        } }
        ref={containerRef}
      > </div>
      {
        shouldShowResult ?
        <ResultView score={score} level={level}></ResultView>
        :
        null
      }
      {
        shouldShowSkip ?
        <SkipButton></SkipButton>
        : null }
    </div>
  );
}
