import React from 'react';
import './App.css';
import MainScene from './MainScene';

function App() {
  return (
    <div className="App">
      <MainScene></MainScene>
    </div>
  );
}

export default App;
