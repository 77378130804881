import * as THREE from 'three';
// import { Shaders, Node, GLSL } from "gl-react";
import { SceneObject, SceneEvent } from './three-utils';
import { GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import Field from './field';
import { Vector3 } from 'three';


export class Cloud implements SceneObject {
  root: THREE.Object3D
  model: THREE.Mesh
  positionRange: number
  scale = 1

  isActive: boolean = true

  constructor() {
    this.root = new THREE.Object3D();
    this.model = null!;
    this.init();

    this.positionRange = 150
    let pos = this.root.position;
    pos.set(
      (Math.random() - 0.5) * this.positionRange,
      20,
      (Math.random() - 0.5) * this.positionRange,
    )
  }

  async init() {
    let models = await Cloud.getModel();
    let model = models[Math.floor(Math.random() * models.length)].clone();

    let s = 5 + Math.random() * 10;
    model.scale.set(s, s, s)
    this.scale = s;
    this.model = model;
    this.root.add(model)
  }

  update(delta: number) {
    this.root.position.x += 0.05;

    if (this.root.position.x > this.positionRange / 2) {
      this.root.position.x -= this.positionRange
    }

    if (this.model) {
      let value = this.positionRange / 2 - Math.abs(this.root.position.x) 
      value /= 10

      let s;
      if (value < 1) {
        s = this.scale * value;
      } else {
        s = this.scale;
      }
      this.model.scale.set(s, s, s)
    }

  }

  remove() {

  }

  on(event: SceneEvent): void {
      
  }

  static models: THREE.Mesh[] | null = null;
  static waitingResolves: ((value: THREE.Mesh[]) => void)[] = [];
  static isLoading = false;
  static getModel() {
    return new Promise<THREE.Mesh[]>((resolve, reject) => {
      if (Cloud.models) {
        resolve(Cloud.models);
      } else {
        this.waitingResolves.push(resolve)
        if (!Cloud.isLoading) {
          this.isLoading = true;
          let loader = new GLTFLoader();
          loader.load('models/cloud.glb', data => {
            let models: THREE.Mesh[] = [];
            data.scene.traverse(obj => {
              if (obj instanceof THREE.Mesh) {
                models.push(obj)
              }
            })
            Cloud.models = models;
            Cloud.waitingResolves.forEach(resolve => resolve(models))
          })
        }
      }
    })
  }
}

export default class Clouds implements SceneObject {
  isActive: boolean = true;
  root: THREE.Object3D = new THREE.Object3D;
  
  clouds: Cloud[] = []

  constructor() {
    const cloudsNum = 10;
    for (let i = 0; i < cloudsNum; i++) {
      var cloud = new Cloud();
      this.root.add(cloud.root);
      this.clouds.push(cloud)
    }
  }

  update(delta: number):void {
    for (let c of this.clouds) {
      c.update(delta);
    }
  }

  remove():void {
    for (let c of this.clouds) {
      c.remove();
    }

    this.root.remove();
  }

  on(event: SceneEvent): void {
      
  }
}

