import * as THREE from 'three';
import { TextureLoader, Vector3 } from 'three';
import { SceneObject, SceneEvent, SceneObjectBase, surface2space } from './three-utils';
import Field from './field';
import { SceneManager } from '../MainScene';
import { Context } from 'vm';

export default class LevelBoard extends SceneObjectBase {
  texture: THREE.CanvasTexture;
  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D
  prevLevel = 0

  constructor() {
    super()

    const {texture, canvas, ctx} = this.createTexture({text: "hello", fontSize: 100})
    this.texture = texture;
    this.canvas = canvas;
    this.ctx = ctx

    const geometry = new THREE.PlaneGeometry(1, 1);
    const material = new THREE.MeshBasicMaterial( { map:texture, transparent: true, alphaTest: 0.5 } );
    const plane = new THREE.Mesh( geometry, material );

    plane.position.set(12, 4, 39);
    plane.scale.multiplyScalar(8);
    plane.rotation.setFromVector3(new Vector3(0, Math.PI, 0))

    this.root.add(plane);
  }

  createTexture(options: {text: string, fontSize: number}) {
    // Canvas要素を作成
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;

    // measureTextするためいったん設定
    const fontFamily = 'lato';
    ctx.font = `bold ${options.fontSize}px '${fontFamily}'`;
    const textWidth = ctx.measureText(options.text); // 文字の横幅を取得

    const size = 256;
    // 幅を指定
    canvas.width = size;
    canvas.height = size;

    // 中央にテキストを描画
    ctx.font = `bold ${options.fontSize}px '${fontFamily}'`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    // ctx.fillStyle = 'rgba(255, 255, 255, 1.0)';
    ctx.fillStyle = 'rgba(0, 0, 0, 1.0)';
    ctx.fillText(options.text, size/2, size/2); // 文字が途切れないように調整。数値はよしなに

    // ↓canvasの文字を確認したいとき。テキストを描画したcanvasをbodyに追加しているだけです。
    // document.body.appendChild(canvas);
    // canvas.style.backgroundColor = '#933';
    // canvas.style.position = 'relative';

    // テクスチャを作成
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = false;
    // ↓ここら辺の設定をしておかないとthree.jsでエラーが出る時がある
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.format = THREE.RGBAFormat;

    return {texture, canvas, ctx};
  }

  drawText(options: {text: string, fontSize: number}) {
    const ctx = this.ctx;
    const fontFamily = 'Lato';
    const size = this.canvas.width;

    ctx.clearRect(0, 0, size, size);

    ctx.font = `bold ${options.fontSize}px '${fontFamily}'`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    // ctx.fillStyle = 'rgba(255, 255, 255, 1.0)';
    ctx.lineWidth = options.fontSize * 0.16
    ctx.strokeStyle = "white"
    ctx.strokeText(options.text, size/2, size/2); // 文字が途切れないように調整。数値はよしなに
    ctx.fillStyle = 'rgba(0, 0, 0, 1.0)';
    ctx.fillText(options.text, size/2, size/2); // 文字が途切れないように調整。数値はよしなに

    this.texture.needsUpdate = true;

  }

  update(delta: number) {
    const level = SceneManager.shared.level
    if (level == this.prevLevel) {
      return
    }

    this.drawText({
      text: `LEVEL ${level}`,
      fontSize: 50,
    })

    this.prevLevel = level;
  }
}
