import * as THREE from 'three';
import { TextureLoader } from 'three';
import { SceneObject, SceneEvent, SceneObjectBase, surface2space } from './three-utils';
import Field from './field';
import { SceneManager } from '../MainScene';

class Message {
  root = new THREE.Object3D()
  index = 0
  messages: Messages
  position = new THREE.Vector3();
  imagePath: string

  constructor(messages: Messages, imagePath: string, initialZ: number = 0) {
    this.imagePath = imagePath;
    this.messages = messages;
    messages.root.add(this.root);
    // const x = this.generateInitialX();
    // const z = zRange.min + (zRange.max - zRange.min) * Math.random();
    // const v = new THREE.Vector3(x, 0, z);
    // this.position.copy(v);
    this.loadTexture();
    this.position.setY(3);
    this.position.setZ(initialZ);
  }

  async loadTexture() {

    const loader = new THREE.TextureLoader()
    const texture = await loader.loadAsync(this.imagePath);
    const geometry = new THREE.PlaneGeometry(1, 1);
    const material = new THREE.MeshPhongMaterial( { map:texture, transparent: true } );
    const plane = new THREE.Mesh( geometry, material );
    plane.scale.multiplyScalar(4);

    this.root.add(plane);
  }

  update(delta: number) {
    const speed = this.messages.speed;
    this.position.add(new THREE.Vector3(0, 0, -delta * speed));

    const {position, rotation} = surface2space(this.position);

    this.root.position.copy(position);
    this.root.rotation.setFromVector3(rotation);

    if (SceneManager.shared.mode !== "intro") {
      this.root.scale.setScalar(0);
    } else {
      this.root.scale.setScalar(1);
    }

    this.root.updateMatrix();
  }
}


export default class Messages implements SceneObject {
  isActive: boolean = true
  root: THREE.Object3D = new THREE.Object3D;
  messages: Message[] = []
  defaultSpeed = 1
  targetSpeed = this.defaultSpeed
  speed = this.defaultSpeed

  constructor() {
    let data = [
      {image: 'images/messages/message-0.png', space: 5 },
      {image: 'images/messages/message-1.png', space: 5 },
      {image: 'images/messages/message-2.png', space: 5 },
      {image: 'images/messages/message-3.png', space: 5 },
      {image: 'images/messages/message-4.png', space: 5 },
      {image: 'images/messages/message-5.png', space: 5 },
      {image: 'images/messages/message-6.png', space: 5 },
      {image: 'images/messages/message-7.png', space: 5 },
    ]


    this.messages = [ ]

    let z = 4
    for (let elem of data) {
      this.messages.push(new Message(this, elem.image, z))
      z += elem.space;
    }
  }

  update(delta: number):void {
    this.speed = THREE.MathUtils.lerp(this.speed, this.targetSpeed, 0.1);

    for (let m of this.messages) {
      m.update(delta);
    }

    const lastMessage = this.messages[this.messages.length - 1];
    // console.log(lastMessage.position.z);
    if ((SceneManager.shared.mode === "intro") && lastMessage.position.z < -3) {
      SceneManager.shared.startGame();
    }
  }

  remove():void {

  }

  on(event: SceneEvent): void {
    if (event.name == "restart") {
      this.targetSpeed = this.defaultSpeed
      this.speed = this.defaultSpeed
    }

    if (event.name == "skip-intro") {
      this.targetSpeed = 100
    }
      
  }
}