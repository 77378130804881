import * as THREE from 'three';
import { SceneObject, SceneEvent } from './three-components/three-utils';

import Tiger from './three-components/tiger';
import Field from './three-components/field';
import Background from './three-components/background';
import Trees from './three-components/trees';
import Cows from './three-components/cows';
import Clouds from './three-components/clouds'
import Messages from './three-components/Messages';
import Obstacles from './three-components/obstacles';
import Instruction from './three-components/Instruction';
import ScoreBoard from './three-components/ScoreBoard';
import LevelBoard from './three-components/LevelBoard';
import Fuji from './three-components/Fuji';
import Eagles from './three-components/Eagles';
import Eggplants from './three-components/Eggplants';


export default class SceneObjects {
  sceneObjects = Array<SceneObject>()
  scene: THREE.Scene

  constructor( scene: THREE.Scene) {
    this.scene = scene;
    this.initObjects();
  }

  initObjects() {
    this.addObject(new Tiger);
    this.addObject(new Field);
    this.addObject(new Background);
    this.addObject(new Trees);
    this.addObject(new Cows);
    this.addObject(new Clouds);
    this.addObject(new Messages);
    this.addObject(new Obstacles);
    this.addObject(new Instruction);
    this.addObject(new ScoreBoard);
    this.addObject(new LevelBoard);
    this.addObject(new Fuji);
    this.addObject(new Eagles);
    this.addObject(new Eggplants);
  }

  addObject(obj: SceneObject) {
    this.sceneObjects.push(obj);
    this.scene.add(obj.root);
  }

  on(event: SceneEvent) {
    for (let obj of this.sceneObjects) {
      obj.on(event);
    }
  }

  update(delta: number) {
    for (let object of this.sceneObjects) {
      object.update(delta);
    }

    this.sceneObjects = this.sceneObjects.filter(obj => obj.isActive);
  }
}
