import * as THREE from 'three';
import { SceneObject, SceneEvent } from './three-utils';
import { GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import Field from './field';
import { Vector3 } from 'three';
import { SceneManager } from '../MainScene';


class Eggplant {
  node = new THREE.Object3D()
  index = 0
  age = 0
  eggplants: Eggplants
  hitRadius = 1
  isActive = false

  position = new THREE.Vector3();

  variants = {
    rotationOffset: Math.PI * 2 * Math.random()
  }

  constructor(eggplants: Eggplants) {
    this.eggplants = eggplants
    const xRange = this.eggplants.xRange;
    const zRange = this.eggplants.zRange;
    const x = this.generateInitialX();
    const z = zRange.min + (zRange.max - zRange.min) * Math.random();
    const v = new THREE.Vector3(x, 0, z);
    this.position.copy(v);
  }

  surface2space(position: THREE.Vector3) {
    const radius = Field.shared.radius;
    const angle = position.clone().divideScalar(radius);
    angle.y = 0;
    const x = radius * Math.sin(angle.x);
    const z = radius * Math.sin(angle.z);
    const y = Math.sqrt(radius * radius - x * x - z * z) - radius;

    return {
      position: new Vector3(x, y, z),
      rotation: new Vector3(angle.z, 0, angle.x),
    };
  }

  respawn() {
    this.isActive = true
    const z = this.eggplants.zRange;
    this.position.setZ(this.position.z + (z.max - z.min));
    this.position.setX(this.generateInitialX());
  }

  generateInitialX() {
    const xr = this.eggplants.xRange;
    let x = xr.min + (xr.max - xr.min) * Math.random();
    return x;
  }

  update(delta: number) {
    this.age += delta
    const speed = Field.shared.speed;
    this.position.add(new THREE.Vector3(0, 0, -delta * speed));

    if (this.position.z < this.eggplants.zRange.min) {
      this.respawn();
    }
    
    const {position, rotation} = this.surface2space(this.position);

    this.node.position.copy(position);
    this.node.rotation.setFromVector3(rotation);

    const rotationSpeed = 2
    this.node.rotateY(this.age * rotationSpeed + this.variants.rotationOffset)

    if (this.isActive) {
      this.node.scale.setScalar(0.3);
    } else {
      this.node.scale.setScalar(0.0);
    }

    this.node.updateMatrix();
  }
}


export default class Eggplants implements SceneObject {
  isActive: boolean = true;
  root = new THREE.Object3D
  mixer: THREE.AnimationMixer | null = null;
  animations: THREE.AnimationClip[] = []
  eggplants: Eggplant[] = []
  meshs: THREE.InstancedMesh[] = []

  static shared: Eggplants = null!
  
  instanceNum = 10;
  xRange = { min: -6, max: 6}
  zRange = { min: -50, max: 80, }


  constructor() {
    Eggplants.shared = this;
    this.loadModel();
  }

  async loadModel() {
    const loader = new GLTFLoader();
    const model = await loader.loadAsync('models/eggplant.glb');
    
    this.makeInstances(model);
  }

  makeInstances(model: GLTF) {

    for (let i = 0; i < this.instanceNum; i++) {
      const tree = new Eggplant(this);
      tree.index = i;
      this.eggplants.push(tree);
    }


    model.scene.traverse(object => {
      if (object instanceof THREE.Mesh) {
        const mesh = object;
        const instancedMesh = new THREE.InstancedMesh(
          mesh.geometry,
          mesh.material,
          this.eggplants.length,
          );

        instancedMesh.castShadow = true
        
        this.meshs.push(instancedMesh);
      }
    })

    for (let mesh of this.meshs) {
      this.root.add(mesh);
    }
  }

  update(delta: number) {
    if (SceneManager.shared.mode == 'intro') {
      return
    }

    for (let tree of this.eggplants) {
      tree.update(delta);
    }

    for (let mesh of this.meshs) {
      for (let tree of this.eggplants) {
        mesh.setMatrixAt(tree.index, tree.node.matrix);
      }
      mesh.instanceMatrix.needsUpdate = true;
    }
  }

  remove() {
  }
  on(event: SceneEvent): void {
    if (event.name == "restart") {
      for (let obj of this.eggplants) {
        obj.isActive = false
      }
    }
  }
}