import * as THREE from 'three';
import { SceneObject, SceneEvent } from './three-utils';
import { GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import Field from './field';
import { Scene, Vector3 } from 'three';
import { totalmem } from 'os';


class Cow {
  node = new THREE.Object3D()
  index = 0
  cows: Cows
  age = 0

  variance = {
    run: Math.random(),
    direction: Math.random(),
  }

  position = new THREE.Vector3();

  constructor(cows: Cows) {
    this.cows = cows
    const xRange = this.cows.xRange;
    const zRange = this.cows.zRange;
    const x = xRange.min + (xRange.max - xRange.min) * Math.random();
    const z = zRange.min + (zRange.max - zRange.min) * Math.random();
    const v = new THREE.Vector3(x, 0, z);
    this.position.copy(v);
  }

  surface2space(position: THREE.Vector3) {
    const radius = Field.shared.radius;
    const angle = position.clone().divideScalar(radius);
    angle.y = 0;
    const x = radius * Math.sin(angle.x);
    const z = radius * Math.sin(angle.z);
    const y = Math.sqrt(radius * radius - x * x - z * z) - radius;

    return {
      position: new Vector3(x, y, z),
      rotation: new Vector3(angle.z, 0, angle.x),
    };
  }

  respawn() {
    const z = this.cows.zRange;
    this.position.setZ(this.position.z + (z.max - z.min));

    const x = this.cows.xRange;
    this.position.setX(x.min + (x.max - x.min) * Math.random());
  }

  update(delta: number) {
    this.age += delta

    const speed = Field.shared.speed;
    this.position.add(new THREE.Vector3(0, 0, 0));

    if (this.position.z < this.cows.zRange.min) {
      this.respawn();
    }
    
    const {position, rotation} = this.surface2space(this.position);

    // micro motion
    const t = this.age;

    position.x += 3 * Math.sin(t * 2 + this.variance.direction * Math.PI * 2);

    rotation.x += 0.1 * Math.sin(t * 40 + this.variance.run * Math.PI * 2);
    rotation.y += 0.5 * Math.cos(t * 2 + this.variance.direction * Math.PI * 2);

    this.node.position.copy(position);
    this.node.rotation.setFromVector3(rotation);
    this.node.updateMatrix();
  }
}


export default class Cows implements SceneObject {
  isActive: boolean = true;
  root = new THREE.Object3D
  mixer: THREE.AnimationMixer | null = null;
  cows: Cow[] = []
  meshs: THREE.InstancedMesh[] = []
  
  cowNum = 300;
  xRange = { min: -50, max: 50, }
  zRange = { min: -30, max: -12, }

  constructor() {
    this.loadModel();
  }

  async loadModel() {
    const loader = new GLTFLoader();
    const model = await loader.loadAsync('models/cow.glb');
    
    this.makeInstances(model);

    const mixer = new THREE.AnimationMixer(model.scene);
    for (let animation of model.animations) {
      let action = mixer.clipAction(animation);
      action.play();
    }

    mixer.timeScale = 5.0;

    this.mixer = mixer;
  }

  makeInstances(model: GLTF) {

    for (let i = 0; i < this.cowNum; i++) {
      const tree = new Cow(this);
      tree.index = i;
      this.cows.push(tree);
    }


    model.scene.traverse(object => {
      if (object instanceof THREE.Mesh) {
        const mesh = object;
        const instancedMesh = new THREE.InstancedMesh(
          mesh.geometry,
          mesh.material,
          this.cows.length,
          );
        
        instancedMesh.castShadow = true
        this.meshs.push(instancedMesh);
      }
    })

    for (let mesh of this.meshs) {
      this.root.add(mesh);
    }
  }

  update(delta: number) {
    this.mixer?.update(delta);

    for (let tree of this.cows) {
      tree.update(delta);
    }

    for (let mesh of this.meshs) {
      for (let tree of this.cows) {
        mesh.setMatrixAt(tree.index, tree.node.matrix);
      }
      mesh.instanceMatrix.needsUpdate = true;
      mesh.matrixWorldNeedsUpdate = true;
    }
  }
  
  on(event: SceneEvent): void {
      
  }

  remove() {
  }
}


