import * as THREE from 'three';
import { SceneObject, SceneEvent } from './three-utils';
import { GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import { threadId } from 'worker_threads';
import { GeometryUtils, Object3D, Vector3 } from 'three';
import { lerp } from 'three/src/math/MathUtils';

export default class Field implements SceneObject {
  isActive: boolean = true;
  root = new THREE.Object3D
  mixer: THREE.AnimationMixer | null = null;
  animations: THREE.AnimationClip[] = []
  radius = 200;
  defaultSpeed = 8;
  targetSpeed = this.defaultSpeed;
  speed = this.targetSpeed;

  model = new THREE.Object3D

  static shared: Field = null!
  
  constructor() {
    Field.shared = this;
    this.loadModel();
  }

  async loadModel() {
      // const loader = new GLTFLoader();
      // const model = await loader.loadAsync('models/field.glb');
      
      // model.scene.traverse(object => {
      //   object.castShadow = true;
      // })

      // // fix color
      // model.scene.traverse(object => {
      //   if (object instanceof THREE.Mesh) {
      //     const material = object.material;
      //     if (material instanceof THREE.MeshStandardMaterial) {
      //       if (material.name === "Material.001") {
      //         material.color = new THREE.Color(0x338833);
      //       }
      //       if (material.name === "Material.002") {
      //         material.color = new THREE.Color(0x44ff44);
      //       }
      //     }
      //   }
      // })

      // this.root.add(model.scene);    
      // this.model = model.scene;

      const loader = new THREE.TextureLoader();
      const texture = await loader.loadAsync("images/field-texture.png")
      const repeatNum = 200
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(repeatNum, repeatNum * 0.5)

      let geometory = new THREE.SphereGeometry(1, 128, 128)
      let material = new THREE.MeshPhongMaterial({
        map: texture
      })
      const sphere = new THREE.Mesh(geometory, material)
      sphere.receiveShadow = true
      sphere.rotation.setFromVector3(new Vector3(0, 0, Math.PI / 2))

      this.model = new THREE.Object3D()
      this.model.add(sphere)
      this.root.add(this.model)

      this.model.scale.setScalar(this.radius);
      this.model.position.setY(-this.radius)
  }

  update(delta: number) {
    this.speed = THREE.MathUtils.lerp(this.speed, this.targetSpeed, 0.1)

    const rotationSpeed = this.speed / this.radius;
    this.model.rotateX(-delta * rotationSpeed);
  }

  remove() {
  }

  on(event: SceneEvent): void {
    if (event.name == "restart") {
      this.speed = this.defaultSpeed
    }
  }
}