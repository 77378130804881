import * as THREE from "three";
import { SceneObjectBase } from "./three-utils";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { HighlightSpanKind } from "typescript";
import { Object3D, SkeletonHelper } from "three";

// import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";

class Eagle extends SceneObjectBase {
  mixer: THREE.AnimationMixer | null = null
  parent: Eagles
  animationStartTime: number

  constructor(parent: Eagles, position: THREE.Vector3, animationStartTime: number) {
    super()
    this.parent = parent
    this.animationStartTime = animationStartTime
    parent.root.add(this.root)
    this.loadModel()

    this.root.scale.setScalar(0.5)
    this.root.position.copy(position)
  }

  async loadModel() {

    const loader = new GLTFLoader();
    const gltf = await loader.loadAsync('models/eagle.glb')

    const model = gltf.scene

    model.traverse(object => object.castShadow = true)

    const mixer = new THREE.AnimationMixer(model)
    this.mixer = mixer;

    for (let animation of gltf.animations) {
      const action = mixer.clipAction(animation);
      action.play()
    }

    this.mixer.update(this.animationStartTime)

    this.root.add(gltf.scene)
  }

  update(delta: number): void {
    this.mixer?.update(delta)
  }
}


export default class Eagles extends SceneObjectBase {
  eagles = Array<Eagle>()

  constructor() {
    super()

    this.eagles.push(new Eagle(this, new THREE.Vector3(4, 5, 10), 0.0))
    this.eagles.push(new Eagle(this, new THREE.Vector3(-4, 5, 11), 0.5))

  }

  update(delta: number): void {
      for (let eagle of this.eagles) {
        eagle.update(delta)
      }
  }

}


