import * as THREE from 'three';
import { SceneObject, SceneEvent } from './three-utils';
import { GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader';

export default class Background implements SceneObject {
  isActive: boolean = true;
  root = new THREE.Object3D
  mixer: THREE.AnimationMixer | null = null;
  animations: THREE.AnimationClip[] = []
  radius = 50;
  model = new THREE.Object3D
  constructor() {
    this.loadModel();
  }

  async loadModel() {
      const loader = new GLTFLoader();
      const model = await loader.loadAsync('models/background.glb');

      model.scene.traverse(object => {
        object.castShadow = true;
      })

      this.root.add(model.scene);    
      this.model = model.scene;
  }

  update(delta: number) {
    const duration = 10
    this.model.rotateZ(delta * -Math.PI*2/duration);
  }

  remove() {
  }
  on(event: SceneEvent): void {
      
  }
}