import * as THREE from 'three';
import { TextureLoader, Vector3 } from 'three';
import { SceneObject, SceneEvent, SceneObjectBase, surface2space } from './three-utils';
import Field from './field';
import { SceneManager } from '../MainScene';


export default class Instruction extends SceneObjectBase {
  initialPosition = new THREE.Vector3(0, 0, 40);
  position = this.initialPosition.clone()

  constructor() {
    super()
    this.loadTexture()
  }

  async loadTexture() {
    const loader = new THREE.TextureLoader()
    const texture = await loader.loadAsync("images/instruction.png");
    const geometry = new THREE.PlaneGeometry(1, 1);
    const material = new THREE.MeshPhongMaterial( { map:texture, transparent: true } );
    const plane = new THREE.Mesh( geometry, material );
    plane.position.set(0, 8, 0);
    plane.scale.multiplyScalar(16);
    plane.rotation.setFromVector3(new Vector3(0, Math.PI, 0))

    this.root.add(plane);
  }

  update(delta: number) {
    const speed = Field.shared.speed;
    this.position.add(new THREE.Vector3(0, 0, -delta * speed));

    if (this.position.z > 40) {
      this.root.scale.setScalar(0);
    }

    const {position, rotation} = surface2space(this.position);

    this.root.position.copy(position);
    this.root.rotation.setFromVector3(rotation);

    this.root.updateMatrix();
  }

  on(event: SceneEvent): void {
    if (event.name == "game-start") {
      this.position = this.initialPosition
      this.root.scale.setScalar(1)
    }
    if (event.name == "restart") {
      this.position = this.initialPosition
      this.root.scale.setScalar(1)
    }
  }
}
