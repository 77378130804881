import * as THREE from 'three';
import { SceneObject, SceneEvent } from './three-utils';
import { GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import { SceneManager } from '../MainScene';
import { Material, Object3D, Scene, Vector3 } from 'three';
import { collapseTextChangeRangesAcrossMultipleVersions, textSpanIntersectsWith } from 'typescript';
import Obstacles from './obstacles';
import Eggplants from './Eggplants';
import Field from './field';
import { threadId } from 'worker_threads';

export default class Tiger implements SceneObject {
  isActive: boolean = true;
  root = new THREE.Object3D
  mixer: THREE.AnimationMixer | null = null;
  isDamaged = false
  isFallingBack = false
  isInvincible = false
  invincibleLimit = 0

  age = 0

  position = new THREE.Vector3()
  shield = new THREE.Mesh()
  targetShieldScale = new THREE.Vector3()

  constructor() {
    this.loadModel();

    this.createShield()
  }

  async loadModel() {
    const loader = new GLTFLoader();
    const model = await loader.loadAsync('models/tiger.glb');
    
    model.scene.traverse(object => {
      object.castShadow = true;
    })

    this.root.add(model.scene);    

    const mixer = new THREE.AnimationMixer(model.scene);
    for (let animation of model.animations) {
      let action = mixer.clipAction(animation);
      action.play();
    }

    mixer.timeScale = 5.0;

    this.mixer = mixer;
  }

  createShield() {
    let geo = new THREE.SphereGeometry(2)
    let mat = new THREE.MeshStandardMaterial({
      color: 0xffff44,
      opacity: 0.8,
      transparent: true,
    })
    let mesh = new THREE.Mesh(geo, mat);
    mesh.scale.setScalar(0);
    this.shield = mesh
    this.root.add(mesh)
  }

  update(delta: number) {
    this.age += delta;

    if (SceneManager.shared.mode === "game") {
      this.updatePos(delta);
      this.updateHit();
      this.updateInvincible();
    }

    this.updateAppearance(delta)
  }

  surface2space(position: THREE.Vector3) {
    const radius = Field.shared.radius;
    const angle = position.clone().divideScalar(radius);
    angle.y = 0;
    const x = radius * Math.sin(angle.x);
    const z = radius * Math.sin(angle.z);
    const y = Math.sqrt(radius * radius - x * x - z * z) - radius;

    return {
      position: new Vector3(x, y, z),
      rotation: new Vector3(angle.z, 0, angle.x),
    };
  }

  updatePos(delta: number) {
      const mouse = SceneManager.shared.mouse;
      this.position.x = mouse.x * 6;

      if (this.isFallingBack) {
        const damageSpeed = 50
        this.position.z -= delta * damageSpeed;

        if (this.position.z < -15) {
          SceneManager.shared.finish();
        }

      } else {
        const healSpeed = 2;
        this.position.z += delta * healSpeed;
        if (this.position.z > 0) {
          this.position.z = 0;
        }
      }

      let {position, rotation} = this.surface2space(this.position)

      const prevPosition = this.root.position.clone();
      this.root.position.lerp(position, 0.2);
      const deltaPos = this.root.position.clone().sub(prevPosition);
      this.root.rotation.setFromVector3(new Vector3(0, deltaPos.x * 5, 0));
  }

  updateHit() {
    if (Obstacles.shared && !this.isInvincible) {
      for (let obstacle of Obstacles.shared.obstacles) {
        if (!obstacle.isActive) {
          continue;
        }
        const pos = obstacle.root.position.clone();
        const dist = pos.sub(this.root.position).length();
        if (dist < obstacle.hitRadius) {
          this.onHit();
        }
      }
    }
  }

  updateInvincible() {

    if (Eggplants.shared) {
      for (let obj of Eggplants.shared.eggplants) {
        if (!obj.isActive) {
          continue
        }

        const pos = obj.node.position.clone();
        const dist = pos.sub(this.root.position).length();
        if (dist < obj.hitRadius) {
          this.onInvincible()
        }
      }
    }

    if (this.invincibleLimit < this.age) {
      this.onFinishInvincible()
    }
  }

  updateAppearance(delta: number) {
    this.mixer?.update(delta);

    if (this.isInvincible) {
      this.targetShieldScale.setScalar(1);
    } else {
      this.targetShieldScale.setScalar(0);
    }

    this.shield.scale.lerp(this.targetShieldScale, 0.1)

    let s = 1.0

    if (this.isDamaged) {
      let cycle = 0.1
      if (this.age % cycle < cycle * 0.5) {
        s = 0;
      }
    }

    this.root.scale.setScalar(s);

  }

  onHit() {
    if (this.isDamaged) {
      return
    }

    this.isDamaged = true
    this.isFallingBack = true
    const damageDuration = 0.1
    setTimeout(() => this.isDamaged = false, 500);
    setTimeout(() => this.isFallingBack = false, damageDuration * 1000);
  }

  onInvincible() {
    this.isInvincible = true
    this.invincibleLimit = this.age + 2;
    const field = Field.shared;
    field.targetSpeed = field.defaultSpeed * 2
  }

  onFinishInvincible() {
    this.isInvincible = false;

    const field = Field.shared;
    field.targetSpeed = field.defaultSpeed;
  }

  remove() {
    this.root.remove();
  }

  on(event: SceneEvent) {
    if (event.name == "restart") {
      this.position = new THREE.Vector3()
    }
  }
}

